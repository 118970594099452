import * as React from "react";
import { Link } from "gatsby";
import { Box, Button, Center, HStack, Text } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <Center py="10">
        <Box>
          <StaticImage width="1/2" src="../images/bitdecoders-404page.png" />
        </Box>
      </Center>
      <Center py="10">
        <HStack>
          <Text
            fontSize={{ base: "sm", md: "xl", lg: "2xl" }}
            fontWeight="black"
          >
            Looks like we are lost. Take me
          </Text>
          <Button
            color="red"
            bgColor="white"
            px="0.5"
            fontSize={{ base: "sm", md: "xl", lg: "2xl" }}
            _hover={{ bg: "white", color: "red" }}
          >
            <Link as="Home" to="/">
              HOME
            </Link>
          </Button>
        </HStack>
      </Center>
    </Layout>
  );
}

export default NotFoundPage;
